import A11yDialog from 'a11y-dialog';
import Flickity from 'flickity';
import select from 'dom-select';

export const Modal = (el) => {
  const ui = {
    el,
    closeBtn: select('.modal__close', el),
    carousel: select('.carousel__container', el),
    iframe: el.querySelector('iframe'),
    video: el.querySelector('iframe[src*="www.youtube.com"], iframe[src*="player.vimeo.com"], video')
  };

  const { id } = ui.el;

  const stopVideo = function (el) {
    if (ui.video) {
      ui.video.src = ui.video.src.replace('&autoplay=1', '').replace('?autoplay=1', '');
    }
  }

  const startVideo = function (el) {
    if (ui.video) {
      // ui.video.src = ui.video.src + (ui.video.src.indexOf('?') < 0 ? '?' : '&') + 'autoplay=1';
      // console.log('autoplay!');
    }
  }

  ui.toggle = document.querySelector(`#opens--${id}`);

  const dialog = new A11yDialog(ui.el);

  const addEvents = () => {
    ui.toggle.addEventListener('click', () => {
      dialog.show();
    });

    dialog.on('show', () => {
      ui.closeBtn.blur();

      if (ui.carousel) {
        const flkty = Flickity.data(ui.carousel);
        ui.carousel.classList.add('is-open');
        flkty.resize();
      }

      startVideo();
    });

    dialog.on('hide', () => {
      if (ui.carousel) {
        ui.carousel.classList.remove('is-open');
      }

      stopVideo();
    });
  };

  const init = () => {
    addEvents();
  };

  init();
};

export default Modal;
