import select from 'dom-select';

export const PageNav = (el) => {
  const ui = {
    el,
    nav: select('.page-nav'),
    toggle: select('.page-nav__heading', el),
  };

  // console.log(ui);

  const addEvents = () => {
    ui.toggle.addEventListener('click', () => {
      if (ui.nav.classList.contains('is-open')) {
        ui.nav.classList.remove('is-open');
      } else {
        ui.nav.classList.add('is-open');
      }
    });
  };

  const init = () => {
    addEvents();
  };

  init();
};

export default PageNav;