export const Expandable = (el) => {
  const ui = {
    el,
    drawer: el.querySelector('.expandable__drawer'),
    toggle: undefined
  };

  const state = {
    startOpen: ui.el.dataset.startOpen === 'true' || false,
    isExpanded: false,
    closedHeight: ui.el.dataset.closedHeight || 0,
    aria: undefined
  };

  const toggle = new Event('toggle'); // eslint-disable-line

  const onToggle = () => {
    if (state.isExpanded) {
      ui.el.style.height = state.closedHeight;

      setTimeout(() => {
        ui.el.classList.remove('is-expanded');
      }, 200); // animation time

      if (state.aria) { // aria when content is hidden completely
        if (ui.toggle) {
          ui.toggle.setAttribute('aria-expanded', false);
        }

        ui.drawer.setAttribute('aria-hidden', true);
      }
    } else {
      const height = ui.drawer.offsetHeight;
      ui.el.style.height = `${height}px`;
      ui.el.classList.add('is-expanded');

      if (state.aria) {
        if (ui.toggle) {
          ui.toggle.setAttribute('aria-expanded', true);
        }

        ui.drawer.setAttribute('aria-hidden', false);
      }
    }

    state.isExpanded = !state.isExpanded;
  };


  const init = () => {
    ui.el.style.height = state.closedHeight;
    state.aria = state.closedHeight === '0';

    ui.toggle = document.querySelector(`#${ui.el.id}-expandable-control`);

    if (state.startOpen) {
      document.onload = onToggle();
    }

    if (ui.toggle) {
      if (state.aria) {
        ui.toggle.setAttribute('aria-expanded', false);
      }

      ui.toggle.addEventListener('toggle', () => {
        onToggle();
      });

      ui.toggle.addEventListener('click', () => {
        ui.toggle.dispatchEvent(toggle);
      });
    }
  };

  init();
};

export default Expandable;
