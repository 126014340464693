import select from 'dom-select';

// el here is `.richtext table`, not `table` or `.table`
// tables from the rich text editor require data
// attributes not easily replicated in markup
export default (el) => {
  const ui = {
    el,
    rows: select.all('tr', el)
  };

  ui.heads = select.all('th', ui.rows[0]);

  ui.rows.forEach((row) => {
    const cells = select.all('td', row);

    cells.forEach((cell, idx) => {
      cell.setAttribute('data-heading', ui.heads[idx].innerText);
    });
  });
};
