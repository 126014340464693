import select from 'dom-select';

export default (el) => {
  const ui = {
    el,
    select: select('.language-select__select', el),
  };

  const addEvents = () => {
    ui.select.addEventListener('change', () => {
      const actionURL = ui.select.value;
      el.submit();
    });
  };

  const init = () => {
    addEvents();
  };

  init();
};
