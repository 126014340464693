/*
  image.init.js

  1. Page loads low-res image
  2. JS mounts, apply blur / gray screen
  3. Large image loads / fails, apply / revert
*/

export default (el) => {
  const ui = {
    el,
    image: el.querySelector('.image'),
    box: el.querySelector('.image__lazy-box'), // has placeholder image (if supplied) + gray window
    panel: el.querySelector('.image__lazy-panel'),
    lowImage: el.querySelector('.image__low-res')
  };

  const { src } = ui.el.dataset;
  const { srcset } = ui.el.dataset;

  const resolveImage = (obj) => { // obj = { src: [src], type: ['src' or 'srcset'] }
    /*
      Promise that creates an Image HTML element from an `obj`, and attempts to
      load the `src` or `srcset` from the dataset into that Image.

      On success, we resolve and return the obj information to be applied
      to the Image inside the page markup.
    */
    const loadImage = (obj) => new Promise((resolve, reject) => {
      const loadedImage = new Image();

      loadedImage[obj.type] = obj.src;
      loadedImage.onload = () => resolve(obj);
      loadedImage.onerror = () => {
        throw new Error(`Unable to load ${obj.type} from ${obj.src}`);
      }
    });

    loadImage(obj).then((obj) => {
      ui.image[obj.type] = obj.src;

      setTimeout(() => {
        ui.el.classList.add('is-loaded');
        ui.el.classList.remove('pre-load');
      }, 500)
    }).catch((e) => {
      /*
        Error response:
        - log in console 'Error loading the src from www.facebook.com/cool-image.png'
        - Revert to previous style state.
      */
      console.log(e.message);

      setTimeout(() => {
        ui.el.classList.remove('pre-load');
      }, 500);
    });
  };

  const onImageView = () => {
    /*
      Sends correct information based on `src` vs `srcset` supply
    */
    if (srcset) {
      resolveImage({src: srcset, type: "srcset"});
    } else {
      resolveImage({src: src, type: "src"});
    }
  }

  const detectBoundingBox = () => {
    /*
      Measures bounding box relative to viewport

      When Image is within the distance of it's own height
      from the viewport window, call a responder
    */
    const box = ui.el.getBoundingClientRect();

    // When the Image wrapper is near the viewport, call responder.
    if (
      box.top >= 0 &&
      box.left >= 0 &&
      box.right <= (window.innerWidth || document.documentElement.clientWidth) &&
      box.bottom - box.height <= (window.innerHeight || document.documentElement.clientHeight)
    ) {
      onImageView(); // responder
    }
  }

  const prepareLoad = () => {
    /*
      When JS loads, we engage our Promise pattern.
      First, we add classes for our panel so we apply the gray filter and blur.
    */

    ui.el.classList.add('pre-load');
  };

  const addEvents = () => {
    /*
      When we scroll, measure lazy images to know when to load them
    */
    window.addEventListener('scroll', () => {
      detectBoundingBox();
    })
  };

  const init = () => {
    /*
      On load, calls in order:
      - adds blur quality to image (if JS fails, Image remains clear)
      - add scroll watch event
      - measure all lazy Images, load if in viewport.
    */
    prepareLoad();
    addEvents();
    detectBoundingBox();
  };

  init();
};
